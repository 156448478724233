import './App.css';
import BusinessContentPannel from './Parts/BusinessContentPannel';
function App() {

  const BusinessContent = [
    {
      title:"ホームページ制作",
      link:"https://cachitto-page.com",
      img:"CachittoTwittercard.jpg",
      text:"カチッとページはシンプルでカチッとしたHP制作をコンセプトに行なっています。初期費用0円、月額制なので、新規事業を立ち上げたタイミングでも事業の看板になりうるHPを運用可能です。",
      btn:"Get more informations",
    },
    {
      title:"動画制作 youtube運用",
      link:"https://youtube.com/playlist?list=PLiu69ST-SqmOK7gHVbmOzO9u8TkHxBN9A&si=ooiOD9l7dnfnmPhY",
      img:"video.png",
      text:"チャンネル運用のための長編動画の制作、shortsやreels,TikTok向けの短い動画のみの作成も可能です。ぜひ一度お問い合わせください。",
      btn:"Check the Works",

    },

    {
      title:"アパレルブランド",
      link:"https://dez.prospority.com/",
      img:"dez.png",
      text:"京都発祥のスポーツブランドDEZ。フットボールユニフォームの制作なども承っていいますので、ぜひお問い合わせください。",
      btn:"Jump to EC Store",

    },

    {
      title:"自動速報システム",
      link:"https://prospority.com/",
      img:"sway.svg",
      text:"Jリーグや社会人リーグなどの試合の試合速報をTwitter上で自動で行えるシステムの販売を行なっています。費用感などに関してはお問い合わせください。",
      btn:"Jump to Website",

    },
    {
      title:"そのほか",
      link:"https://pinterest.com/oratos97",
      img:"other.png",
      text:"名刺制作や広告制作、SNSの運用代行なども可能ですのでぜひ一度ご相談ください！",
      btn:"Check the Works",

    },





  ]

  return (
    <div className="bg-Gray font-Gill font-bold text-center">
      <h1 className='text-3xl py-5'>Sotaro Kawai <br /> <span className='text-Orange'> Official Website</span></h1>

      <div>
        <h2 className='text-2xl py-5'>Business</h2>

        <div>
                {BusinessContent && BusinessContent.map ((content)=> (
                 <BusinessContentPannel 
               title={content.title}
               link={content.link}
               img={content.img}
               text={content.text}
               btn={content.btn}

                />

                ))}

            </div>






      </div>

      <div>
        <h2 className='text-2xl'>Contact</h2>
        <a className='text-2xl py-3 block' href="mailto:oratos.kawai@gmail.com">oratos.kawai@gmail.com</a>
        <a className='text-2xl py-3 block' href="https://instagram.com/oratos97">instagram</a>
        <a className='text-2xl py-3 block' href="https://twitter.com/oratos97">Twitter</a>


      </div>

      <div>

      
      </div>
    </div>
    
  );
}

export default App;
