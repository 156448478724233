function BusinessContentPannel(props) {
    return (
        <div className="w-4/5 md:w-1/3 m-auto py-7">
          <h2 className="text-left py-2 text-White text-xl">{props.title}</h2>
          <img src={"../Assets/"+ props.img} className="m-auto" alt="" />
          <p className="text-White py-2 leading-loose text-left font-medium">{props.text}</p>
          <button className="bg-Orange py-2 px-5 rounded-sm my-3"><a className="text-xl" href={props.link}>{props.btn}</a></button>
        </div>
    )
}

export default BusinessContentPannel;